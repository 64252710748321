import React from 'react';
import { Shadowrun } from '../dice';
import { DateTime } from 'luxon';
import MdTrash from 'react-ionicons/lib/MdTrash';
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBIcon,
} from 'mdbreact';
import { Die, retrieveRollsFromLocalStorage } from '../components';

function ShadowrunDie(props) {
  const dieColor = props.type[0] === 'exploded' ? 'blue' : 'black';
  const valueColor = { '': 'white', 'success': 'green', 'one': 'red' }[props.type[1]];

  return <Die sides={6} result={props.result} dieColor={dieColor} valueColor={valueColor} />;
}

function DiceSelector(props) {
  return (
    <div className='roll-select'>
      <div className='die-wrapper small' onClick={props.changeDice.bind(this, -5)}>
        <Die sides={6} result={'-5'} />
      </div>
      <div className='die-wrapper small' onClick={props.changeDice.bind(this, -1)}>
        <Die sides={6} result={'-1'} />
      </div>
      <div className='die-wrapper'>
        <Die sides={6} />
        <MDBBadge color='primary' pill>{props.dice}</MDBBadge>
      </div>
      <div className='die-wrapper small' onClick={props.changeDice.bind(this, 1)}>
        <Die sides={6} result={'+1'} />
      </div>
      <div className='die-wrapper small' onClick={props.changeDice.bind(this, 5)}>
        <Die sides={6} result={'+5'} />
      </div>
    </div>);
}

function Success(props) {
  if (props.successes > 0) {
    return (
      <div className='success-failure success'>
        <MDBIcon icon='trophy' /> {`${props.successes} Success${props.successes === 1 ? '' : 'es'}`}
      </div>
    );
  }
  return (
    <div className='success-failure failure'>
      <MDBIcon icon='exclamation-triangle' /> 0 Successes
    </div>
  );
}

function Glitch(props) {
  if (props.glitch) {
    return (
      <div className='success-failure failure'>
        <MDBIcon icon='skull' /> {`${props.successes === 0 ? 'Critical ' : ''} Glitch`}
      </div>
    );
  }
  return '';
}

function Rolls(props) {
  return (
    <div className='rolls'>
      <h3>Rolls</h3>
      <div className='clear-icon' onClick={props.clear.bind(this)}><MdTrash /></div>
      {props.rolls.map((roll) => <Roll roll={roll} key={roll.time.toMillis()} />)}
    </div>);
}

function Roll(props) {
  return (
    <MDBCard>
      <div className='roll-datetime'>{props.roll.time.toFormat('dd.LL.yyyy HH:mm:ss')}</div>
      <MDBCardBody>
        {props.roll.rolledDice.map((rolledDie, index) =>
          <ShadowrunDie type={rolledDie.type} result={rolledDie.result} key={index} />)}
        <hr />
        <Success successes={props.roll.successes} />
        <Glitch successes={props.roll.successes} glitch={props.roll.glitch} />
      </MDBCardBody>
    </MDBCard>
  );
}

export class ShadowrunRoller extends React.Component {
  constructor(props) {
    super(props);
    this.state = { game: new Shadowrun(parseInt(props.edition, 10)), rolls: [], dice: 0,
      explode: false, targetNumber: 4, validTargetNumber: true };
    this.changeDice = this.changeDice.bind(this);
    this.roll = this.roll.bind(this);
    this.resetDice = this.resetDice.bind(this);
    this.clearRolls = this.clearRolls.bind(this);
    this.toggleExplode = this.toggleExplode.bind(this);
    this.changeTargetNumber = this.changeTargetNumber.bind(this);

    this.state.rolls = retrieveRollsFromLocalStorage(`shadowrun${props.edition}`);
  }

  roll() {
    const newRoll = { ...this.state.game.roll(
      this.state.dice, { explode: this.state.explode, targetNumber: this.state.targetNumber }),
    time: DateTime.local() };

    if (newRoll.rolledDice.length > 0) {
      const rolls = this.state.rolls;

      rolls.unshift(newRoll);
      if (rolls.length > 20) {
        rolls.pop();
      }
      this.setState({ rolls });
      localStorage.setItem(`shadowrun${this.props.edition}`, JSON.stringify(rolls));
    }
  }

  resetDice() {
    this.setState({ dice: 0 });
  }

  clearRolls() {
    this.setState({ rolls: [] });
  }

  toggleExplode(event) {
    this.setState({ explode: event.target.checked });
  }


  changeDice(number) {
    let dice = this.state.dice;

    dice += number;
    if (dice < 0) {
      dice = 0;
    } else if (dice > 50) {
      dice = 50;
    }
    this.setState({ dice });
  }

  changeTargetNumber(event) {
    this.setState({ validTargetNumber: event.target.checkValidity(),
      targetNumber: event.target.value });
  }

  render() {
    return (
      <div className='content'>
        <DiceSelector dice={this.state.dice} changeDice={this.changeDice} />
        <div className='buttons'>
          <div className='inline-block'>
            <MDBBtn color='primary' size='sm' onClick={this.roll}
              disabled={!this.state.validTargetNumber}>Roll</MDBBtn>
            <MDBBtn color='primary' size='sm' onClick={this.resetDice} >Reset Dice</MDBBtn>
          </div>
          <div className='inline-block'>
            {this.props.edition >= 4 ? (
              <div className='pretty p-default'>
                <input type='checkbox' checked={this.state.explode} onChange={this.toggleExplode} />
                <div className='state p-primary'>
                  <label>Explode</label>
                </div>
              </div>
            ) : (<>
              Target: <input type='text' className='form-control form-control-sm'
                value={this.state.targetNumber} onChange={this.changeTargetNumber}
                required pattern='[0-9]{1,2}' style={{ 'width': '2.1rem' }} /></>
            )}
          </div>
        </div>
        {this.state.rolls.length > 0 &&
          <Rolls rolls={this.state.rolls} clear={this.clearRolls} />}
      </div>
    );
  }
}

