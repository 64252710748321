const randomInteger = max => Math.floor(Math.random() * max) + 1;

export class Shadowrun {
  constructor(edition) {
    this._edition = edition;
  };

  static _rollDieFive(explode = false) {
    let roll = randomInteger(6);
    const rolledDice = [];
    let successes = 0;
    let ones = (roll === 1) ? 1 : 0;
    // let type = `standard${roll >= 5 && '_success'}`;
    let type = ['standard', ''];

    rolledDice.push({ type, result: roll });
    successes += (roll >= 5) ? 1 : 0;
    while (explode && roll === 6) {
      roll = randomInteger(6);
      // type = `exploded${roll >= 5 && '_success'}`;
      type = ['exploded', ''];
      rolledDice.push({ type, result: roll });
      successes += (roll >= 5) ? 1 : 0;
      ones += (roll === 1) ? 1 : 0;
    }
    return { successes, ones, rolledDice };
  }

  static _rollDieVariable(targetNumber) {
    let roll = randomInteger(6);
    let score = roll;

    while (roll === 6) {
      roll = randomInteger(6);
      score += roll;
    }
    const successes = (score >= targetNumber) ? 1 : 0;
    const ones = (score === 1) ? 1 : 0;
    const type = ['standard', ''];
    const rolledDice = [{ type, result: score }];

    return { successes, ones, rolledDice };
  }

  static _setDiceTypes(rolledDice, targetNumber = 5) {
    for (const rolledDie of rolledDice) {
      if (rolledDie.result >= targetNumber) {
        rolledDie.type[1] = 'success';
      } else if (rolledDie.result === 1) {
        rolledDie.type[1] = 'one';
      }
    }
  }

  roll(nDice, { explode = false, targetNumber = 5 } = { explode: false, targetNumber: 5 }) {
    const rolledDice = [];
    let successes = 0;
    let ones = 0;
    let glitch = false;

    for (let i = 0; i < nDice; i++) {
      const rollResult = (this._edition >= 4 ?
        Shadowrun._rollDieFive(explode) : Shadowrun._rollDieVariable(targetNumber));

      rolledDice.push(...rollResult.rolledDice);
      successes += rollResult.successes;
      ones += rollResult.ones;
    }
    Shadowrun._setDiceTypes(rolledDice, this._edition === 3 ? targetNumber : 5);
    if (this._edition === 3) {
      glitch = (ones === nDice);
    } else if (this._edition === 4) {
      glitch = (ones >= nDice / 2);
    } else {
      glitch = (ones > nDice / 2);
    }
    return { successes, rolledDice, glitch };
  }
}



export class SWRPG {
  constructor() {
    this.symbols = {
      SUCCESS: 'success',
      ADVANTAGE: 'advantage',
      TRIUMPH: 'triumph',
      FAILURE: 'failure',
      THREAT: 'threat',
      DESPAIR: 'despair',
      LIGHT: 'light',
      DARK: 'dark',
    };

    this.dice = {
      boost: {
        1: [],
        2: [],
        3: [this.symbols.SUCCESS],
        4: [this.symbols.SUCCESS, this.symbols.ADVANTAGE],
        5: [this.symbols.ADVANTAGE, this.symbols.ADVANTAGE],
        6: [this.symbols.ADVANTAGE],
      },
      setback: {
        1: [],
        2: [],
        3: [this.symbols.FAILURE],
        4: [this.symbols.FAILURE],
        5: [this.symbols.THREAT],
        6: [this.symbols.THREAT],
      },
      ability: {
        1: [],
        2: [this.symbols.SUCCESS],
        3: [this.symbols.SUCCESS],
        4: [this.symbols.SUCCESS, this.symbols.SUCCESS],
        5: [this.symbols.ADVANTAGE],
        6: [this.symbols.ADVANTAGE],
        7: [this.symbols.SUCCESS, this.symbols.ADVANTAGE],
        8: [this.symbols.ADVANTAGE, this.symbols.ADVANTAGE],
      },
      difficulty: {
        1: [],
        2: [this.symbols.FAILURE],
        3: [this.symbols.FAILURE, this.symbols.FAILURE],
        4: [this.symbols.THREAT],
        5: [this.symbols.THREAT],
        6: [this.symbols.THREAT],
        7: [this.symbols.THREAT, this.symbols.THREAT],
        8: [this.symbols.FAILURE, this.symbols.THREAT],
      },
      proficiency: {
        1: [],
        2: [this.symbols.SUCCESS],
        3: [this.symbols.SUCCESS],
        4: [this.symbols.SUCCESS, this.symbols.SUCCESS],
        5: [this.symbols.SUCCESS, this.symbols.SUCCESS],
        6: [this.symbols.ADVANTAGE],
        7: [this.symbols.SUCCESS, this.symbols.ADVANTAGE],
        8: [this.symbols.SUCCESS, this.symbols.ADVANTAGE],
        9: [this.symbols.SUCCESS, this.symbols.ADVANTAGE],
        10: [this.symbols.ADVANTAGE, this.symbols.ADVANTAGE],
        11: [this.symbols.ADVANTAGE, this.symbols.ADVANTAGE],
        12: [this.symbols.TRIUMPH],
      },
      challenge: {
        1: [],
        2: [this.symbols.FAILURE],
        3: [this.symbols.FAILURE],
        4: [this.symbols.FAILURE, this.symbols.FAILURE],
        5: [this.symbols.FAILURE, this.symbols.FAILURE],
        6: [this.symbols.THREAT],
        7: [this.symbols.THREAT],
        8: [this.symbols.FAILURE, this.symbols.THREAT],
        9: [this.symbols.FAILURE, this.symbols.THREAT],
        10: [this.symbols.THREAT, this.symbols.THREAT],
        11: [this.symbols.THREAT, this.symbols.THREAT],
        12: [this.symbols.DESPAIR],
      },
      force: {
        1: [this.symbols.DARK],
        2: [this.symbols.DARK],
        3: [this.symbols.DARK],
        4: [this.symbols.DARK],
        5: [this.symbols.DARK],
        6: [this.symbols.DARK],
        7: [this.symbols.DARK, this.symbols.DARK],
        8: [this.symbols.LIGHT],
        9: [this.symbols.LIGHT],
        10: [this.symbols.LIGHT, this.symbols.LIGHT],
        11: [this.symbols.LIGHT, this.symbols.LIGHT],
        12: [this.symbols.LIGHT, this.symbols.LIGHT],
      },
    };
  }

  _rollDie(type) {
    const roll = randomInteger(Object.keys(this.dice[type]).length);

    return this.dice[type][roll];
    //~ return { face: `swrpg.${type}.${roll}`, result: this.dice[type][roll] };

  }

  roll(dice) {
    const types = ['proficiency', 'ability', 'boost', 'challenge', 'difficulty', 'setback', 'force'];
    const rolledDice = [];
    const result = { [this.symbols.SUCCESS]: 0, [this.symbols.ADVANTAGE]: 0,
      [this.symbols.TRIUMPH]: 0, [this.symbols.FAILURE]: 0,
      [this.symbols.THREAT]: 0, [this.symbols.DESPAIR]: 0,
      [this.symbols.LIGHT]: 0, [this.symbols.DARK]: 0 };

    for (const type of types) {
      for (let i = 0; i < dice[type] ? dice[type] : 0; i++) {
        const rollResult = this._rollDie(type);

        rolledDice.push({ type, result: rollResult });
        for (const symbol of rollResult) {
          result[symbol] += 1;
        }
      }
    }
    result[this.symbols.SUCCESS] += result[this.symbols.TRIUMPH];
    result[this.symbols.FAILURE] += result[this.symbols.DESPAIR];
    if (result[this.symbols.SUCCESS] >= result[this.symbols.FAILURE]) {
      result[this.symbols.SUCCESS] -= result[this.symbols.FAILURE];
      result[this.symbols.FAILURE] = 0;
    } else {
      result[this.symbols.FAILURE] -= result[this.symbols.SUCCESS];
      result[this.symbols.SUCCESS] = 0;
    }
    if (result[this.symbols.ADVANTAGE] >= result[this.symbols.THREAT]) {
      result[this.symbols.ADVANTAGE] -= result[this.symbols.THREAT];
      result[this.symbols.THREAT] = 0;
    } else {
      result[this.symbols.THREAT] -= result[this.symbols.ADVANTAGE];
      result[this.symbols.ADVANTAGE] = 0;
    }
    return { result, rolledDice };
  }
}
