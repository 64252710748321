import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SWRPGRoller } from './systems/swrpg';
import Navbar from './navbar';
import { ShadowrunRoller } from './systems/shadowrun';

export const pages = {
  '/': { title: 'Home', component: Home, props: {} },
  '/shadowrun3': { title: 'Shadowrun 3', component: ShadowrunRoller, props: { 'edition': '3' } },
  '/shadowrun4': { title: 'Shadowrun 4', component: ShadowrunRoller, props: { 'edition': '4' } },
  '/shadowrun5': { title: 'Shadowrun 5', component: ShadowrunRoller, props: { 'edition': '5' } },
  '/swrpg': { title: 'Star Wars RPG (FFG)', component: SWRPGRoller, props: {} },
};

function App() {
  return (
    <Router>
      <Navbar />
      <div className='App'>
        {Object.keys(pages).map((path, index) => {
          const TagName = pages[path].component;

          return <Route exact path={path} key={path} render={
            (props) => <TagName {...props} {...pages[path].props} />} />;
        })}
      </div>
    </Router>
  );
}

function Home(props) {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

export default App;
