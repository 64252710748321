import React from 'react';
import { SWRPG } from '../dice';
import { IconSvg } from '../images/icons/icons';
import { DateTime } from 'luxon';
import MdTrophy from 'react-ionicons/lib/MdTrophy';
import MdWarning from 'react-ionicons/lib/MdWarning';
import MdTrash from 'react-ionicons/lib/MdTrash';
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody } from 'mdbreact';
import { Die, retrieveRollsFromLocalStorage } from '../components';

function SwrpgDie(props) {
  const [sides, dieColor, iconColor] = {
    'boost': [6, 'light-blue', 'black'],
    'setback': [6, 'black', 'white'],
    'ability': [8, 'green', 'black'],
    'difficulty': [8, 'purple', 'white'],
    'proficiency': [12, 'yellow', 'black'],
    'challenge': [12, 'red', 'white'],
    'force': [12, 'white', 'black'],
  }[props.type];
  let result = props.result;

  if (Array.isArray(result)) {
    result = result.sort().join('-');
  }
  return <Die system='swrpg' sides={sides} result={result} dieColor={dieColor}
    valueColor={iconColor} />;
}

function DiceSelector(props) {
  return (
    <div className='roll-select'>
      { ['proficiency', 'ability', 'boost', 'challenge', 'difficulty', 'setback', 'force']
        .map((type, index) =>
          <div className='die-wrapper' onClick={props.addDie.bind(this, type)} key={type}>
            <SwrpgDie type={type} />
            <MDBBadge color='primary' pill>{props.dice[type]}</MDBBadge>
          </div>
        )}
    </div>);
}

function Success(props) {
  if (props.success) {
    return (
      <div className='success-failure success'>
        <MdTrophy /> Success
      </div>
    );
  }
  return (
    <div className='success-failure failure'>
      <MdWarning /> Failure
    </div>
  );
}

function Rolls(props) {
  return (
    <div className='rolls'>
      <h3>Rolls</h3>
      <div className='clear-icon' onClick={props.clear.bind(this)}><MdTrash /></div>
      {props.rolls.map((roll) => {
        if (typeof roll.result === 'number') {
          return <RollD100 roll={roll} key={roll.time.toMillis()} />;
        }
        return <Roll roll={roll} key={roll.time.toMillis()} />;
      })}
    </div>);
}

function Roll(props) {
  return (
    <MDBCard>
      <div className='roll-datetime'>{props.roll.time.toFormat('dd.LL.yyyy HH:mm:ss')}</div>
      <MDBCardBody>
        {props.roll.rolledDice.map((rolledDie, index) =>
          <SwrpgDie type={rolledDie.type} result={rolledDie.result} key={index} />)}
        <hr />
        {['triumph', 'despair', 'success', 'failure', 'advantage', 'threat', 'light', 'dark']
          .map((symbol, index) => {
            if (props.roll.result[symbol] > 0) {
              return (
                <div className='result-icon-wrapper' key={`${symbol}`}>
                  <IconSvg icon={`swrpg-${symbol}`} color='black' />
                  <MDBBadge color='primary' pill>{props.roll.result[symbol]}</MDBBadge>
                </div>);
            }
            return '';
          })}
        <Success success={props.roll.result.success > 0} />
      </MDBCardBody>
    </MDBCard>
  );
}

function RollD100(props) {
  const die = props.roll.rolledDice[0];
  const modifier = parseInt(die.modifier, 10);

  return (
    <MDBCard>
      <div className='roll-datetime'>{props.roll.time.toFormat('dd.LL.yyyy HH:mm:ss')}</div>
      <MDBCardBody>
        <div className='roll-datetime'>{props.roll.time.toFormat('dd.LL.yyyy HH:mm:ss')}</div>
        <div className='result-sum'><Die sides={100} result={die.result} />
          <span> {modifier >= 0 ? '+' : '-'} {Math.abs(modifier) } = {props.roll.result}</span></div>
      </MDBCardBody>
    </MDBCard>);
}

export class SWRPGRoller extends React.Component {
  constructor(props) {
    super(props);
    this.state = { game: new SWRPG(), rolls: [], modifier: '+0', validModifier: true, dice: {
      ability: 0, proficiency: 0, difficulty: 0, challenge: 0, boost: 0, setback: 0, force: 0 } };
    this.addDie = this.addDie.bind(this);
    this.roll = this.roll.bind(this);
    this.resetDice = this.resetDice.bind(this);
    this.rollD100 = this.rollD100.bind(this);
    this.clearRolls = this.clearRolls.bind(this);
    this.changeModifier = this.changeModifier.bind(this);

    this.state.rolls = retrieveRollsFromLocalStorage('swrpg');
  }

  roll() {
    const newRoll = { ...this.state.game.roll(this.state.dice), time: DateTime.local() };

    if (newRoll.rolledDice.length > 0) {
      const rolls = this.state.rolls;

      rolls.unshift(newRoll);
      if (rolls.length > 20) {
        rolls.pop();
      }
      this.setState({ rolls });
      localStorage.setItem('swrpg', JSON.stringify(rolls));
    }
  }

  rollD100() {
    const number = Math.floor(Math.random() * 100) + 1;
    const newRoll = { result: number + parseInt(this.state.modifier, 10), time: DateTime.local(),
      rolledDice: [{ type: 'd100', result: number, modifier: this.state.modifier }] };
    const rolls = this.state.rolls;

    rolls.unshift(newRoll);
    if (rolls.length > 20) {
      rolls.pop();
    }
    this.setState({ rolls });
  }

  addDie(type) {
    const dice = this.state.dice;

    if (dice[type] < 5) {
      dice[type] += 1;
    }
    this.setState({ dice });
  }

  resetDice() {
    this.setState({ dice: { ability: 0, proficiency: 0, difficulty: 0, challenge: 0,
      boost: 0, setback: 0, force: 0 } });
  }

  clearRolls() {
    this.setState({ rolls: [] });
  }

  changeModifier(event) {
    this.setState({ validModifier: event.target.checkValidity(), modifier: event.target.value });
  }

  render() {
    return (
      <div className='content'>
        <DiceSelector dice={this.state.dice} addDie={this.addDie} />
        <div className='buttons'>
          <div className='inline-block'>
            <MDBBtn color='primary' size='sm' onClick={this.roll}>Roll</MDBBtn>
            <MDBBtn color='primary' size='sm' onClick={this.resetDice} >Reset Dice</MDBBtn>
          </div>
          <div className='inline-block'>
            <MDBBtn color='primary' size='sm' onClick={this.rollD100}
              disabled={!this.state.validModifier}>Roll D100</MDBBtn>
            <input type='text' className='form-control form-control-sm' value={this.state.modifier}
              onChange={this.changeModifier} required pattern='[+-]?[0-9]{1,3}'/>
          </div>
        </div>
        {this.state.rolls.length > 0 &&
          (<Rolls rolls={this.state.rolls} clear={this.clearRolls} />)}
      </div>
    );
  }
}

