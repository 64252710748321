import React from 'react';

import { ReactComponent as SwrpgAdvantageAdvantage } from './swrpg-advantage-advantage.svg';
import { ReactComponent as SwrpgAdvantageSuccess } from './swrpg-advantage-success.svg';
import { ReactComponent as SwrpgAdvantage } from './swrpg-advantage.svg';
import { ReactComponent as SwrpgDarkDark } from './swrpg-dark-dark.svg';
import { ReactComponent as SwrpgDark } from './swrpg-dark.svg';
import { ReactComponent as SwrpgDespair } from './swrpg-despair.svg';
import { ReactComponent as SwrpgFailureFailure } from './swrpg-failure-failure.svg';
import { ReactComponent as SwrpgFailureThreat } from './swrpg-failure-threat.svg';
import { ReactComponent as SwrpgFailure } from './swrpg-failure.svg';
import { ReactComponent as SwrpgForceOutline } from './swrpg-force-outline.svg';
import { ReactComponent as SwrpgForceSplit } from './swrpg-force-split.svg';
import { ReactComponent as SwrpgForce } from './swrpg-force.svg';
import { ReactComponent as SwrpgLightLight } from './swrpg-light-light.svg';
import { ReactComponent as SwrpgLight } from './swrpg-light.svg';
import { ReactComponent as SwrpgSuccessSuccess } from './swrpg-success-success.svg';
import { ReactComponent as SwrpgSuccess } from './swrpg-success.svg';
import { ReactComponent as SwrpgThreatThreat } from './swrpg-threat-threat.svg';
import { ReactComponent as SwrpgThreat } from './swrpg-threat.svg';
import { ReactComponent as SwrpgTriumph } from './swrpg-triumph.svg';

export function IconSvg(props) {
  switch(props.icon) {
    case 'swrpg-advantage-advantage':
      return <SwrpgAdvantageAdvantage className={props.color} />;
    case 'swrpg-advantage-success':
      return <SwrpgAdvantageSuccess className={props.color} />;
    case 'swrpg-advantage':
      return <SwrpgAdvantage className={props.color} />;
    case 'swrpg-dark-dark':
      return <SwrpgDarkDark className={props.color} />;
    case 'swrpg-dark':
      return <SwrpgDark className={props.color} />;
    case 'swrpg-despair':
      return <SwrpgDespair className={props.color} />;
    case 'swrpg-failure-failure':
      return <SwrpgFailureFailure className={props.color} />;
    case 'swrpg-failure-threat':
      return <SwrpgFailureThreat className={props.color} />;
    case 'swrpg-failure':
      return <SwrpgFailure className={props.color} />;
    case 'swrpg-force-outline':
      return <SwrpgForceOutline className={props.color} />;
    case 'swrpg-force-split':
      return <SwrpgForceSplit className={props.color} />;
    case 'swrpg-force':
      return <SwrpgForce className={props.color} />;
    case 'swrpg-light-light':
      return <SwrpgLightLight className={props.color} />;
    case 'swrpg-light':
      return <SwrpgLight className={props.color} />;
    case 'swrpg-success-success':
      return <SwrpgSuccessSuccess className={props.color} />;
    case 'swrpg-success':
      return <SwrpgSuccess className={props.color} />;
    case 'swrpg-threat-threat':
      return <SwrpgThreatThreat className={props.color} />;
    case 'swrpg-threat':
      return <SwrpgThreat className={props.color} />;
    case 'swrpg-triumph':
      return <SwrpgTriumph className={props.color} />;
    default:
      return '';
  }
}
