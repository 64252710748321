import React from 'react';

import { ReactComponent as D10 } from './d10.svg';
import { ReactComponent as D100 } from './d100.svg';
import { ReactComponent as D12 } from './d12.svg';
import { ReactComponent as D20 } from './d20.svg';
import { ReactComponent as D4 } from './d4.svg';
import { ReactComponent as D6 } from './d6.svg';
import { ReactComponent as D8 } from './d8.svg';

export function DieSvg(props) {
  switch (props.sides) {
  case 10:
    return <D10 className={props.color} />;
  case 100:
    return <D100 className={props.color} />;
  case 12:
    return <D12 className={props.color} />;
  case 20:
    return <D20 className={props.color} />;
  case 4:
    return <D4 className={props.color} />;
  case 6:
    return <D6 className={props.color} />;
  case 8:
    return <D8 className={props.color} />;
  default:
    return '';
  }
}
