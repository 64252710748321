import { IconSvg } from './images/icons/icons';
import React from 'react';
import { DateTime } from 'luxon';
import { DieSvg } from './images/dice/dice';

export function Die(props) {
  const dieColor = props.dieColor ? props.dieColor : 'gray';
  const valueColor = props.valueColor ? props.valueColor : 'white';

  return (
    <div className='die'>
      <div className='die-shape'>
        <DieSvg sides={props.sides} color={dieColor}/>
      </div>
      <Value system={props.system} result={props.result} color={valueColor}/>
    </div>
  );
}

function Value(props) {
  if (typeof props.result === 'string' && !['+', '-'].includes(props.result[0])) {
    return (
      <div className='die-symbol'>
        <IconSvg icon={`${props.system}-${props.result}`} color={props.color}/>
      </div>
    );
  } else {
    return (
      <div className={`die-number ${props.color}-text`}>
        {props.result}
      </div>
    );
  }
}

export function retrieveRollsFromLocalStorage(system) {
  const rolls = JSON.parse(localStorage.getItem(system)) || [];

  for (const roll of rolls) {
    roll.time = DateTime.fromISO(roll.time);
  }
  return rolls;
}
