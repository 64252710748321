import React, { Component } from 'react';
import {
  MDBNavbar,
  NavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBNavbarBrand,
}
  from 'mdbreact';
import { withRouter } from 'react-router-dom';
import { pages } from './App';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.paths = Object.keys(pages).filter(path => path !== '/');
    this.paths.sort(((first, second) => pages[first].title < pages[second].title ? -1 : 1));
    this.paths.unshift('/');
    this.state = { collapseID: '' };
  }

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
};

render() {
  return (
    <MDBNavbar color='unique-color' dark>
      <MDBContainer>
        <MDBNavbarBrand>
          {pages[this.props.location.pathname].title}
        </MDBNavbarBrand>
        <MDBNavbarToggler onClick={this.toggleCollapse('navbarCollapse')} />
        <MDBCollapse id='navbarCollapse' isOpen={this.state.collapseID} navbar>
          <NavbarNav left>
            {this.paths.map((path, index) =>
              <MDBNavItem key={path} onClick={this.toggleCollapse('navbarCollapse')}>
                <MDBNavLink exact to={path} activeClassName='selected'>{pages[path].title}</MDBNavLink>
              </MDBNavItem>
            )}
          </NavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
}

export default withRouter(Navbar);
